
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































































































































.home-hero {
  position: relative;
  min-height: 40rem;

  &.before-onboard {
    opacity: 0;
  }

  @include mq(m) {
    height: 55rem;
  }

  @include mq(xl) {
    height: 70rem;
    margin-bottom: $spacing * 6;
  }
}

.home-hero-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.video-loop.home-hero__video {
  @include get-all-space;

  &::after {
    @include get-all-space;

    content: '';
    display: block;
    /* stylelint-disable indentation */
    background: linear-gradient(
        57.72deg,
        rgba($c-dark-grey, 1) 23.33%,
        rgba($c-dark-grey, 0) 63.84%
      ),
      linear-gradient(180deg, $c-dark-grey 0%, rgba($c-dark-grey, 0) 19.14%);
    /* stylelint-enable indentation */
    opacity: 0.25;
  }

  .before-onboard & {
    clip-path: inset(0% 100% 0% 0%);
  }
}

.home-hero__content {
  position: relative;
  z-index: 1;
  margin-top: $spacing * 4;
  padding-top: $spacing * 4;
  padding-bottom: $spacing * 4;
}

.home-hero__title {
  max-width: 44rem;
  color: $c-white;

  @include mq(l) {
    max-width: 58rem;
  }

  @include mq(xxl) {
    max-width: 70rem;
  }
}

.home-hero__cta .action--btn--link {
  color: $c-white;
}

.home-hero__promo {
  position: absolute;
  z-index: 1;
  bottom: $spacing * 0.5;

  @include mq($until: m) {
    // override wrapper
    padding-right: $spacing * 0.5 !important;
  }

  @include mq(m) {
    bottom: $spacing;
  }

  @include mq(l) {
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.home-hero__promo__cta {
  margin-left: auto;
}
