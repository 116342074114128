
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































.home {
  position: relative;
}

.home__title {
  color: $c-mustard;
}

.home__links {
  @extend %list-nostyle;

  text-align: center;
}
